import { useCallback, useEffect, useMemo, useState } from "react";

import produce from "immer";
import { PackageInterface } from "../context/interfaces/package";
import useAppStore from "../context/appStore";
import { DpdPriceInterface } from "../context/interfaces/dpdPrice-interface";
import {
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const DeliveryOrder = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const theme = useTheme();

  const [
    selectedShippmentId,
    deliveryOrderPickupDate,
    getShippments,
    getPrice,
    getExtras,
    getCargoPrice,
    createDeliveryOrder,
  ] = useAppStore((state) => [
    state.selectedShippmentId,
    state.deliveryOrderPickupDate,
    state.apis.getShippments,
    state.apis.getPrice,
    state.apis.getExtras,
    state.apis.getCargoPrice,
    state.apis.createDeliveryOrder,
  ]);
  const [insuranceFlag, setInsuranceFlag] = useState(false);
  const [passFlag, setPassFlag] = useState(false);
  const [temperatureFlag, setTemperatureFlag] = useState(false);
  const [selectedTariff, setSelectedTariff] = useState("");
  const [packaging, setPackaging] = useState("");
  const [crateAvaliable, setCrateAvaliable] = useState(false);
  const [palleteAvaliable, setPalleteAvaliable] = useState(false);
  const [temperatureAvaliable, setTemperatureAvaliable] = useState(false);
  const [tariffs, setTariffs] = useState<DpdPriceInterface[] | undefined>(
    undefined
  );
  const [cargoPrice, setCargoPrice] = useState<number | undefined>(undefined);
  const [expandCargoPriceMenu, setExpandCargoPriceMenu] =
    useState<boolean>(false);

  //* ------- use Memo - static --------- *//
  //* packages
  const getPackages = useCallback(() => {
    return state.packages;
  }, [state.packages]);
  const packages: PackageInterface[] = useMemo(
    () => getPackages(),
    [getPackages]
  );

  //* weight
  const getWeight = useCallback(() => {
    if (packages.length === 0) {
      return;
    }
    const itemsWeight = packages
      .reduce((acc, cur) => acc + cur.weight, 0)
      .toFixed(1);
    return +itemsWeight;
  }, [packages]);
  const weight = useMemo(() => getWeight(), [getWeight]);

  //* volume
  const getVolume = useCallback(() => {
    if (packages.length === 0) {
      return;
    }
    const itemsVolume = packages
      .reduce((acc, cur) => acc + cur.volume, 0)
      .toFixed(3);
    return +itemsVolume;
  }, [packages]);
  const volume = useMemo(() => getVolume(), [getVolume]);

  //* QTY
  const getQty = useCallback(() => {
    if (packages.length === 0) {
      return;
    }
    return packages.reduce((acc, cur) => acc + cur.qty, 0);
  }, [packages]);
  const qty = useMemo(() => getQty(), [getQty]);

  //* address
  const getAddressNameStr = useCallback(() => {
    if (packages.length === 0) {
      return;
    }
    const address = packages[0].address;
    return address
      ? (address.street.includes("Центральная зона")
          ? "Тепловский тракт"
          : address.street) +
          (address.streetAbbr === "проезд" && !address.street.includes("проезд")
            ? " " + address.streetAbbr
            : "") +
          (address.house ? ", д " + address.house : "") +
          (address.building ? ", корп " + address.building : "") +
          (address.structure ? ", стр " + address.structure : "") +
          (address.flat ? ", оф " + address.flat : "")
      : "НУЖНО ВЫБРАТЬ АДРЕС В БИРКЕ !!!";
  }, [packages]);
  const addressNameStr = useMemo(
    () => getAddressNameStr(),
    [getAddressNameStr]
  );

  //* po Name
  const getPoString = useCallback(() => {
    if (packages.length === 0) {
      return;
    }
    let str = "";
    for (const p of packages) {
      str = str + p.purchaseOrder + ", ";
    }
    return str.substring(0, str.length - 2);
  }, [packages]);
  const poNames = useMemo(() => getPoString(), [getPoString]);
  //* ------- use Memo - static --------- *//

  //* set crate/pallete and email is exists on first render
  useEffect(() => {
    if (packages.length === 0) {
      return;
    }
    let crateFlag = packages.some((e) => e.crate);
    let palleteFlag = packages.some((e) => e.pallete);
    if (crateFlag && palleteFlag) {
      useAppStore.setState({
        notification:
          "В выбранных заказах пристуствует и обрешетка и пллетный борт - выбери нужное руками",
      });
    } else if (crateFlag && !palleteFlag) {
      setPackaging("Деревянная обрешётка");
    } else if (!crateFlag && palleteFlag) {
      setPackaging("Палетный борт");
    }

    if (packages.length > 0 && packages[0].address.emailForPass) {
      setPassFlag(true);
    }
  }, [deliveryOrderPickupDate, packages]);

  //* set header
  useEffect(() => {
    useAppStore.setState({
      headerString: deliveryOrderPickupDate
        ? deliveryOrderPickupDate.locale("ru").format("D MMMM YYYY")
        : null,
    });
  }, [deliveryOrderPickupDate]);

  //* при первом заходе обновляем стоимость груза
  useEffect(() => {
    getCargoPrice(packages.map((p) => p.id)).then((data) => {
      if (data && data.cargoPrice !== "0") {
        setCargoPrice(data.cargoPrice);
      } else {
        setExpandCargoPriceMenu(true);
      }
    });
  }, [getCargoPrice, packages]);

  //* пересчитываем стоимость
  const calculatePrices = useCallback(
    (data: DpdPriceInterface[] | null) => {
      let tariffData: DpdPriceInterface[] = [];
      if (data) {
        tariffData = data;
      } else if (tariffs) {
        tariffData = tariffs;
      }
      if (!tariffData) {
        return;
      }
      const draft = [];
      for (const tariff of tariffData) {
        const obj = {
          cratePrice: tariff.cratePrice,
          deliveryTime: tariff.deliveryTime,
          finalPrice:
            tariff.intercityPrice +
            (insuranceFlag && cargoPrice
              ? Math.ceil((cargoPrice * 0.3) / 100)
              : 0) +
            (packaging === "Деревянная обрешётка" ? tariff.cratePrice : 0) +
            (packaging === "Палетный борт" ? tariff.palletePrice : 0),
          intercityPrice: tariff.intercityPrice,
          palletePrice: tariff.palletePrice,
          rawDays: tariff.rawDays,
          serviceCode: tariff.serviceCode,
          serviceName: tariff.serviceName,
        };
        draft.push(obj);
        setTariffs(draft);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cargoPrice, insuranceFlag, packaging]
  );

  //* вызываем по изменению зависимостей в calculatePrice()
  useEffect(() => {
    calculatePrices(null);
  }, [calculatePrices]);

  //* выбираем тариф и обновляем доступность упаковок
  const selectTariff = (serviceCode: string) => {
    setSelectedTariff(serviceCode);
    getExtras(
      deliveryOrderPickupDate.format("YYYY-MM-DD"),
      packages[0].address.destinationId,
      serviceCode
    ).then((data) => {
      if (data) {
        if (data.some((e) => e.code === "ОБР")) {
          setCrateAvaliable(true);
        } else {
          if (packaging === "Деревянная обрешётка") {
            setPackaging("");
            useAppStore.setState({
              notification: "Палетный борт не доступен - удален из заказа",
            });
          }
        }

        if (data.some((e) => e.code === "ПБО")) {
          setPalleteAvaliable(true);
        } else {
          if (packaging === "Деревянная обрешётка") {
            setPackaging("");
            useAppStore.setState({
              notification: "Палетный борт не доступен - заменен на обрешетку",
            });
          }
        }

        if (data.some((e) => e.code === "ТРМ")) setTemperatureAvaliable(true);
      }
    });
  };

  //* Расчет стоимости в ДПД
  const getDeliveryPrice = () => {
    const pacIds = packages.map((p) => p.id);
    getPrice(pacIds, insuranceFlag, packaging).then((data) => {
      if (data) {
        calculatePrices(data);
      }
    });
  };

  const onSubmit = () => {
    const usedTariff = tariffs?.find((t) => t.serviceCode === selectedTariff);
    const obj = {
      tariff: selectedTariff,
      price: usedTariff?.finalPrice,
      rawDays: usedTariff?.rawDays,
    };

    const date = deliveryOrderPickupDate.format("YYYY-MM-DD");

    createDeliveryOrder(
      packages.map((p) => p.id),
      cargoPrice ? cargoPrice : null,
      insuranceFlag,
      passFlag,
      temperatureFlag,
      obj,
      packaging,
      date
    ).then((orderId: number) => {
      if (selectedShippmentId) {
        getShippments();
      } else {
        if (orderId) {
          useAppStore.setState(
            produce((draft) => {
              draft.pac.deliveryOrderId = orderId;
            })
          );
        }
      }
      setTimeout(() => {
        navigate(-1);
        // navigation.goBack(null);
      }, 700);
    });
  };

  const renderSize = () => {
    if (weight && volume) {
      return (
        <label>
          {weight} кг / {volume} м3
        </label>
      );
    }
    return <label> </label>;
  };

  const styles = {
    container: {
      flex: 1,
      paddingTop: 15,
      paddingRight: 10,
      paddingLeft: 10,
      flexDirection: "column",
    },
    devider: {
      marginHorizontal: 10,
      borderBottomWidth: 0.4,
    },
  };

  return (
    <div>
      <div
        style={{
          borderRadius: 10,
          width: "100%",
        }}
      >
        <Box
          style={{
            height: 30,
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <label style={{ fontSize: 12, fontWeight: "bold" }}>
            ХАРАКТЕРИСТИКИ
          </label>
        </Box>
        <Box
          style={{
            backgroundColor: theme.palette.background.paper,
            paddingLeft: 16,
            paddingRight: 16,
            height: 40,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <label>Заказы: </label>
          <Box
            style={{ width: 280, justifyContent: "flex-end", display: "flex" }}
          >
            <label
              style={packages.length < 5 ? { fontSize: 16 } : { fontSize: 12 }}
            >
              {poNames}
            </label>
          </Box>
        </Box>
        <Divider style={styles.devider} />
        <Box
          style={{
            backgroundColor: theme.palette.background.paper,
            paddingLeft: 16,
            paddingRight: 16,
            height: 40,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <label>Адрес: </label>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label style={{ alignSelf: "flex-end" }}>
              {packages.length > 0 ? packages[0].destination : ""}
            </label>
            <label style={{ alignSelf: "flex-end", fontSize: 12 }}>
              {packages.length > 0 ? addressNameStr : ""}
            </label>
          </div>
        </Box>
        <Divider style={styles.devider} />
        <Box
          style={{
            backgroundColor: theme.palette.background.paper,
            paddingLeft: 16,
            paddingRight: 16,
            height: 40,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <label>Колличество мест</label>
          <label>{packages.length > 0 ? qty : ""}</label>
        </Box>
        <Divider style={styles.devider} />
        <div
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: theme.palette.background.paper,
            height: 40,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <label>Вес / Объем</label>
          {packages.length > 0 ? renderSize() : null}
        </div>
        <Divider style={styles.devider} />
      </div>

      <Box
        style={{
          width: "100%",
        }}
      >
        <Box
          style={{
            height: 30,
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <label style={{ fontSize: 12, fontWeight: "bold" }}>ОПЦИИ</label>
        </Box>
        <Box
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: theme.palette.background.paper,
            height: 40,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <label>Страховка</label>
            {!expandCargoPriceMenu ? (
              <label style={{ fontSize: 12, fontWeight: "400" }}>
                {"стоимость груза - " +
                  (cargoPrice + "")
                    .replace(/(\.\d{2})\d*/, "$1")
                    .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") +
                  " руб"}
              </label>
            ) : null}
          </Box>

          <label>
            {insuranceFlag
              ? cargoPrice && cargoPrice !== 0
                ? Math.ceil((cargoPrice * 0.3) / 100) + " руб"
                : null
              : null}
          </label>
          <Switch
            style={{ marginRight: 10 }}
            checked={insuranceFlag}
            // disabled={!insuranceFlag}
            onChange={() => setInsuranceFlag(!insuranceFlag)}
          />
        </Box>
        <Divider style={styles.devider} />

        {expandCargoPriceMenu && insuranceFlag ? (
          <div>
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor: theme.palette.background.paper,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
              }}
            >
              <label>Стоиомость груза</label>

              <TextField
                type={"number"}
                multiline={false}
                InputProps={{
                  sx: { height: 40, width: 120 },
                  inputProps: {
                    style: { textAlign: "right" },
                  },
                }}
                value={cargoPrice}
                onFocus={(event) => {
                  event.target.select();
                }}
                onContextMenu={(e) => e.preventDefault()}
                onChange={(event) => setCargoPrice(+event.target.value)}
              />
            </div>
            <Divider style={styles.devider} />
          </div>
        ) : null}

        <Box
          style={{
            alignItems: "center",
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: theme.palette.background.paper,
            height: 40,
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <label>Пропуск</label>
          <Switch
            style={{ marginRight: 10 }}
            checked={passFlag}
            onChange={() => setPassFlag(!passFlag)}
          />
        </Box>
        <Divider style={styles.devider} />
        <Box
          style={{
            alignItems: "center",
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: theme.palette.background.paper,
            height: 40,
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <label>Температурный режим</label>
          <Switch
            style={{ marginRight: 10 }}
            checked={temperatureFlag}
            disabled={!temperatureAvaliable}
            onChange={() => setTemperatureFlag(!temperatureFlag)}
          />
        </Box>
      </Box>

      <ToggleButtonGroup
        color="primary"
        value={packaging}
        exclusive
        style={{ marginTop: 20, justifyContent: "center", display: "flex" }}
        onChange={(event, value) => setPackaging(value)}
        aria-label="Platform"
      >
        <ToggleButton disabled={!selectedTariff} value="">
          нет
        </ToggleButton>
        <ToggleButton disabled={!crateAvaliable} value="Деревянная обрешётка">
          Обрешётка
        </ToggleButton>
        <ToggleButton disabled={!palleteAvaliable} value="Палетный борт">
          Палетный борт
        </ToggleButton>
      </ToggleButtonGroup>

      {tariffs ? (
        <div
          style={{
            marginTop: 8,
            width: "100%",
            flex: 1,
          }}
        >
          <div
            style={{
              height: 30,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: 12, fontWeight: "bold" }}>
              СТОИМОСТЬ ДОСТАВКИ
            </label>
          </div>

          <List
            style={{
              padding: 0,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            {tariffs.map((item, i) => (
              <ListItemButton
                key={i}
                style={{
                  height: 40,
                  justifyContent: "space-between",
                }}
                divider
                onClick={() => selectTariff(item.serviceCode)}
              >
                <Box style={{ flexDirection: "row", display: "flex" }}>
                  {selectedTariff === item.serviceCode ? (
                    <CheckCircleIcon
                      style={{ width: 24, marginRight: 11 }}
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <Box style={{ width: 35, height: 40 }}></Box>
                  )}
                  <Box style={{ alignSelf: "center" }}>
                    <label>{item.serviceName}</label>
                  </Box>
                </Box>
                <Box
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: 150,
                    display: "flex",
                  }}
                >
                  <Box>
                    <label>
                      {(item.finalPrice + "")
                        .replace(/(\.\d{2})\d*/, "$1")
                        .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ") + " руб "}
                    </label>
                  </Box>
                  <Box>
                    <label>{item.deliveryTime}</label>
                  </Box>
                </Box>
              </ListItemButton>
            ))}
          </List>
        </div>
      ) : (
        <div style={{ flex: 1 }}></div>
      )}

      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button variant="contained" onClick={() => getDeliveryPrice()}>
          Рассчитать
        </Button>
        <Button
          variant="contained"
          onClick={() => onSubmit()}
          disabled={
            !selectedTariff ||
            (packaging === "Палетный борт" && !palleteAvaliable) ||
            (packaging === "Деревянная обрешётка" && !crateAvaliable)
          }
        >
          Оформить
        </Button>
      </div>
    </div>
  );
};

export default DeliveryOrder;
