import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { PackageInterface } from "../context/interfaces/package";
import { AddressInterface } from "../context/interfaces/address-interface";
import { ShippmentInterface } from "../context/interfaces/shippment-interface";
import useAppStore from "../context/appStore";
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";

const PackageList = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [shippments, selectedShippmentId] = useAppStore((state) => [
    state.shippments,
    state.selectedShippmentId,
  ]);
  const [ids, setIds] = useState<number[]>([]);

  const [keys, setKeys] = useState<string[] | undefined>();
  const [selectedShippment, setSelectedShippment] = useState<
    ShippmentInterface | undefined
  >();

  useEffect(() => {
    useAppStore.setState({ photos: [], pac: null, pacId: null });
  }, []);

  useEffect(() => {
    setSelectedShippment(shippments.find((s) => s.id === selectedShippmentId));
  }, [shippments, selectedShippmentId]);

  const getShippmentName = (shippment: ShippmentInterface) => {
    let name = "";
    if (shippment.id === 1 || shippment.id === 2 || shippment.id === 3) {
      name = "СКЛАД";
    } else if (shippment.id === 4 || shippment.id === 5 || shippment.id === 6) {
      name = "НА ОТГРУЗКУ";
    } else {
      name = dayjs(shippment.shippmentDate).locale("ru").format("D MMMM YYYY");
    }
    return name;
  };

  useEffect(() => {
    if (selectedShippment) {
      useAppStore.setState({
        headerString: getShippmentName(selectedShippment),
      });

      const keys = selectedShippment!.packages.reduce<string[]>((acc, cur) => {
        let key;
        if (cur.destination) {
          key = cur.destination;
        } else {
          key = "Направление не указано";
        }
        if (!acc.includes(key)) {
          acc.push(key);
        }
        return acc;
      }, []);

      if (keys) {
        const sortedKeys = keys.sort((a, b) => a.localeCompare(b));
        sortedKeys.push(
          sortedKeys.splice(sortedKeys.indexOf("Направление не указано"), 1)[0]
        );
        setKeys(sortedKeys);
      }
    }
  }, [selectedShippment]);

  const navigateToDeliveryOrder = (ids: number[]) => {
    const packages = selectedShippment?.packages.reduce(
      (acc: PackageInterface[], cur) => {
        if (ids.some((i) => i === cur.id)) {
          acc.push(cur);
        }
        return acc;
      },
      []
    );

    if (!packages) {
      useAppStore.setState({
        notification: "нет отправок ДПД",
      });
      return;
    }
    const noQtyFlag = packages.some((p) => !p.qty || p.qty === 0);
    const noWeightFlag = packages.some((p) => !p.weight || p.weight === 0);
    const noVolumeFlag = packages.some((p) => !p.volume || p.volume === 0);

    if (noQtyFlag) {
      useAppStore.setState({
        notification: "не указано кол-во мест",
      });
      return;
    } else if (noWeightFlag) {
      useAppStore.setState({
        notification: "не указан вес отправления",
      });
      return;
    } else if (noVolumeFlag) {
      useAppStore.setState({
        notification: "не указан объем отправления",
      });
      return;
    }
    const pac = packages[0];
    let date = null;
    if (
      pac.shippment.id === 1 ||
      pac.shippment.id === 2 ||
      pac.shippment.id === 3 ||
      pac.shippment.id === 4 ||
      pac.shippment.id === 5 ||
      pac.shippment.id === 6
    ) {
      date = dayjs(new Date());
    } else {
      date = dayjs(pac.shippment.shippmentDate);
    }

    useAppStore.setState({
      deliveryOrderPickupDate: date,
    });

    navigate("/deliveryOrder", {
      state: {
        packages: packages,
      },
    });
  };

  const selectPackage = (pac: PackageInterface) => {
    useAppStore.setState({
      pacId: pac.id,
      pac: pac,
    });
    navigate("/package", {
      state: {
        fromRoute: "packageList",
      },
    });
  };

  const checkItem = (pac: PackageInterface, arr: PackageInterface[]) => {
    const inpurIds = arr.map((i) => i.id);
    if (!inpurIds.includes(ids[0])) {
      setIds([pac.id]);
    } else {
      const previouslyAdded = arr.find((p) => p.id === ids[0]);

      if (
        !pac.address ||
        !previouslyAdded?.address ||
        pac.address.street !== previouslyAdded?.address.street ||
        pac.address.house !== previouslyAdded?.address.house
      ) {
        setIds([pac.id]);
      } else {
        setIds([...ids, pac.id]);
      }
    }
  };

  const uncheckItem = (id: number) => {
    setIds(ids.filter((i) => i !== id));
  };

  const renderItem = (item: string, keyIndex: number) => {
    let arr: PackageInterface[] = [];

    if (selectedShippment) {
      if (item === "Направление не указано") {
        const noDestination = selectedShippment?.packages.filter(
          (p) => !p.destination
        );
        arr = [...noDestination];
      } else {
        const noAddress = selectedShippment?.packages.filter(
          (p) => !p.address && p.destination === item
        );
        const hasDestinationAndAddress = selectedShippment?.packages.filter(
          (p) => p.destination === item && p.address
        );
        const filteredHas = hasDestinationAndAddress.sort((a, b) =>
          a.address.street.localeCompare(b.address.street)
        );

        arr = [...noAddress, ...filteredHas];
      }
    }

    const pacsWithoutDeliveryOrder = arr.filter((p) => !p.deliveryOrderId);

    const checkIds = () => {
      const found = pacsWithoutDeliveryOrder.some((el) => ids.includes(el.id));
      if (found) {
        return true;
      } else {
        return false;
      }
    };

    const checkOtherKeySelected = () => {
      let check = true;
      for (const i of ids) {
        if (arr.findIndex((p) => p.id === i) === -1) {
          check = false;
        }
      }
      return check;
    };

    const checkAddress = () => {
      if (pacsWithoutDeliveryOrder.length > 0) {
        const hasDestinationAndAddress = pacsWithoutDeliveryOrder.filter(
          (p) => p.destination && p.address
        );

        if (hasDestinationAndAddress.length > 0) {
          let addressStr =
            hasDestinationAndAddress[0].address.street +
            hasDestinationAndAddress[0].address.house;
          if (
            pacsWithoutDeliveryOrder.length !== hasDestinationAndAddress.length
          ) {
            return false;
          }
          return hasDestinationAndAddress.every(
            (p) => p.address.street + p.address.house === addressStr
          );
        } else {
          return false;
        }
      }
      return false;
    };

    const getAddressNameStr = (address: AddressInterface) => {
      return (
        (address.street.includes("Центральная зона")
          ? "Тепловский тракт"
          : address.street) +
        (address.streetAbbr === "проезд" && !address.street.includes("проезд")
          ? " " + address.streetAbbr
          : "") +
        (address.house ? ", д " + address.house : "") +
        (address.building ? ", корп " + address.building : "") +
        (address.structure ? ", стр " + address.structure : "") +
        (address.flat ? ", оф " + address.flat : "")
      );
    };

    const getDivider = (length: number, i: number): boolean => {
      if (length - 1 !== i) {
        return true;
      }
      return false;
    };

    return (
      <Box key={keyIndex}>
        <Box
          style={{
            display: "flex",
            paddingRight: 10,
            paddingLeft: 15,
            height: 40,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {item}
          {checkOtherKeySelected() ? (
            checkIds() ? (
              <Button
                onClick={() => navigateToDeliveryOrder(ids)}
                style={{
                  fontSize: "11px",
                  paddingTop: "8px",
                  flexBasis: "30vw",
                  height: "100%",
                }}
              >
                Отгрузить выбранные
              </Button>
            ) : checkAddress() ? (
              <Button
                onClick={() =>
                  navigateToDeliveryOrder(
                    pacsWithoutDeliveryOrder.map((p) => p.id)
                  )
                }
                style={{
                  fontSize: "11px",
                  paddingTop: "8px",
                  flexBasis: "30vw",
                  height: "100%",
                }}
              >
                Отгрузить все
              </Button>
            ) : null
          ) : null}
        </Box>
        <List style={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}>
          {arr.map((item, i) => (
            <ListItem
              key={i}
              style={{ padding: 0 }}
              divider={getDivider(arr.length, i)}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  backgroundColor: theme.palette.background.paper,
                  width: "100%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {item.deliveryOrderId ? (
                    <Box
                      style={{
                        paddingLeft: 12,
                        paddingRight: 5,
                        display: "flex",
                      }}
                    >
                      <CheckCircleIcon
                        sx={{ color: theme.palette.primary.main }}
                      />
                    </Box>
                  ) : ids.includes(item.id) ? (
                    <IconButton
                      style={{
                        paddingLeft: 12,
                        paddingRight: 5,
                        display: "flex",
                      }}
                      aria-label="Example"
                      onClick={(e) => {
                        e.stopPropagation();
                        uncheckItem(item.id);
                      }}
                    >
                      <CheckCircleOutlineIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      style={{
                        paddingLeft: 12,
                        paddingRight: 5,
                        display: "flex",
                      }}
                      aria-label="Example"
                      onClick={(e) => {
                        e.stopPropagation();
                        checkItem(item, arr);
                      }}
                    >
                      <RadioButtonUncheckedIcon />
                    </IconButton>
                  )}

                  <ButtonBase
                    component="div"
                    onClick={(e) => selectPackage(item)}
                    style={{
                      height: "100%",
                      width: "100%",
                      paddingRight: 20,
                      paddingLeft: 5,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      paddingTop: 3,
                      paddingBottom: 3,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box style={{ fontWeight: "600", flex: 4 }}>
                        {item.purchaseOrder}
                      </Box>
                      <Box
                        style={{
                          fontSize: 14,
                          fontWeight: 300,
                          alignSelf: "center",
                          flex: 2,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.trackNumber}
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box style={{ flex: 4 }}>
                        <Box style={{ fontWeight: "300", fontSize: 12 }}>
                          {item.customer?.shortName}
                        </Box>
                        <Box style={{ fontWeight: "300", fontSize: 12 }}>
                          {item.address ? getAddressNameStr(item.address) : ""}
                        </Box>
                        <Box style={{ fontWeight: "300", fontSize: 12 }}>
                          Перевозчик: {item.carrier}
                        </Box>
                      </Box>
                      <Box style={{ flex: 2 }}>
                        <Box style={{ fontWeight: "300", fontSize: 12 }}>
                          Мест: {!item.qty || item.qty === 0 ? "" : item.qty}
                        </Box>
                        <Box style={{ fontWeight: "300", fontSize: 12 }}>
                          Вес:{" "}
                          {!item.weight || item.weight === 0
                            ? ""
                            : item.weight + " кг"}
                        </Box>
                        <Box style={{ fontWeight: "300", fontSize: 12 }}>
                          Объем:{" "}
                          {!item.volume || item.volume === 0
                            ? ""
                            : item.volume + " м3"}
                        </Box>
                      </Box>
                    </Box>
                  </ButtonBase>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return selectedShippment?.packages.length !== 0 ? (
    <Box>
      <List style={{ padding: 0 }}>
        {keys?.map((dest, i) => renderItem(dest, i))}
      </List>
    </Box>
  ) : null;
};

export default PackageList;
