import axiosInstance from "../../utils/Helpers";
import { DpdPriceInterface } from "../interfaces/dpdPrice-interface";
import { DpdExtra } from "../interfaces/dpdExtra";
import useAppStore from "../appStore";

export const getCargoPrice = async (ids: number[]) => {
  try {
    const res = await axiosInstance.post("/mobile/getCargoPrice/", {
      ids,
    });
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    useAppStore.setState({
      notification: "Ошибка при запросе на сервер",
    });
  }
};

export const getPriceAction = async (
  ids: number[],
  insuranceFlag: boolean,
  packaging: string
) => {
  try {
    const res = await axiosInstance.post("/mobile/getDeliveryPrice/", {
      ids,
      insuranceFlag,
      packaging,
    });
    if (res.data) {
      const arr: DpdPriceInterface[] = res.data[0].return.sort(
        (a: DpdPriceInterface, b: DpdPriceInterface) =>
          a.finalPrice - b.finalPrice
      );
      return arr;
    }
  } catch (err) {
    useAppStore.setState({
      notification: "Ошибка при запросе на сервер",
    });
  }
};

export const getExtrasAction = async (
  date: string,
  destinationId: number,
  tariff: string
) => {
  try {
    const res = await axiosInstance.post("/carrier/getDpdExtras", {
      date,
      destinationId,
      tariff,
    });
    if (res.data) {
      const result: DpdExtra[] = res.data;
      return result;
    }
  } catch (err) {
    useAppStore.setState({
      notification: "Ошибка при запросе на сервер",
    });
  }
};

export const createDeliveryOrderAction = async (
  ids: number[],
  cargoPrice: number | null,
  insuranceFlag: boolean,
  passFlag: boolean,
  temperatureFlag: boolean,
  tariff: any,
  packaging: string,
  date: string
) => {
  try {
    const result = await axiosInstance.post("/mobile/createDeliveryOrder/", {
      ids,
      cargoPrice,
      insuranceFlag,
      passFlag,
      temperatureFlag,
      tariff,
      packaging,
      date,
    });

    return result.data ? result.data : null;
  } catch (err) {
    useAppStore.setState({
      notification: "Может заказ и создался ) посмотри на сайтике",
    });
  }
};
