import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import useAuthStore from "../context/authStore";
import { useNavigate } from "react-router-dom";

const LoginScreen = () => {
  const [login, isLoggedIn] = useAuthStore((state) => [
    state.login,
    state.isLoggedIn,
  ]);
  const [userLogin, setName] = React.useState("");
  const [userPassword, setPassword] = React.useState("");
  let navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/scanner");
    }
  }, [isLoggedIn, navigate]);

  const onSubmit = () => {
    login(userLogin, userPassword);
  };

  const styles = {
    input: {
      marginBottom: 20,
      width: 200,
      // flex: 1,
      height: 40,
      justifyContent: "center",
    },
  };

  return (
    // <div style={[styles.container, { backgroundColor: colors.background }]}>
    // <div style={{
    //   flex: 1,
    //   paddingRight: 30,
    //   paddingLeft: 30,
    //   display: "flex",
    //   flexDirection: "column",
    //   justifyContent: "center",
    //   alignItems: "center",
    // }}>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 200,
      }}
    >
      <TextField
        variant="outlined"
        style={styles.input}
        label="Логин"
        value={userLogin}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setName(event.target.value)
        }
      />
      <TextField
        variant="outlined"
        style={styles.input}
        label="Пароль"
        value={userPassword}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(event.target.value)
        }
      />
      <Button variant="contained" onClick={() => onSubmit()}>
        Войти
      </Button>
    </div>
    // </div>
  );
};

export default LoginScreen;
