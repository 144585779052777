import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useEffect } from "react";
import { ShippmentInterface } from "../context/interfaces/shippment-interface";
import useAppStore from "../context/appStore";
import { Box, List, ListItemButton } from "@mui/material";
import { useNavigate } from "react-router";

const Shippments = () => {
  const navigate = useNavigate();
  const [shippments, getShippments] = useAppStore((state) => [
    state.shippments,
    state.apis.getShippments,
  ]);

  const selectShippment = (shippment: ShippmentInterface) => {
    useAppStore.setState({
      selectedShippmentId: shippment.id,
    });

    navigate("/packageList");
  };

  useEffect(() => {
    useAppStore.setState({
      headerString: "Отгрузки",
    });
  }, []);

  useEffect(() => {
    getShippments();
  }, [getShippments]);

  const getShippmentName = (shippment: ShippmentInterface) => {
    let name = "";
    if (shippment.id === 1 || shippment.id === 2 || shippment.id === 3) {
      name = "СКЛАД";
    } else if (shippment.id === 4 || shippment.id === 5 || shippment.id === 6) {
      name = "НА ОТГРУЗКУ";
    } else {
      name = dayjs(shippment.shippmentDate).locale("ru").format("DD MMMM YYYY");
    }
    return name;
  };

  return (
    <Box>
      <List style={{ padding: 0 }}>
        {shippments.map((item, i) => (
          <ListItemButton
            key={i}
            style={{
              height: 45,
              paddingLeft: 20,
              justifyContent: "space-between",
            }}
            divider
            onClick={() => selectShippment(item)}
          >
            <div>{getShippmentName(item)}</div>
            <div style={{ fontSize: 14, fontWeight: 300, flexBasis: 110 }}>
              отправлений: {item.packages.length}
            </div>
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

export default Shippments;
