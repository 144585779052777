import { create } from "zustand";
import { PackageInterface } from "./interfaces/package";
import { PhotoInterface } from "./interfaces/photo";
import dayjs, { type Dayjs } from "dayjs";

import {
  getPhotosQtyAction,
  getPhotosAction,
  addPhotoAction,
  updatePhotoAction,
  deletePhotoAction,
} from "./actions/photoActions";
import { getQRAction } from "./actions/QRActions";
import { updatePackageAction } from "./actions/packageActions";
import { getShippmentsAction } from "./actions/shippmentActions";
import {
  getPriceAction,
  getExtrasAction,
  createDeliveryOrderAction,
  getCargoPrice,
} from "./actions/deliveryOrderActions";
import { ShippmentInterface } from "./interfaces/shippment-interface";
import { DpdExtra } from "./interfaces/dpdExtra";
import { DpdPriceInterface } from "./interfaces/dpdPrice-interface";
import { PurchaseOrderInterface } from "./interfaces/purchaseOrder-interface";
import { getPurchaseOrderByNameAction } from "./actions/purchaseOrderActions";

interface AppStore {
  apis: {
    getQR: (id: number) => void;
    updatePackage: (id: number, obj: any) => Promise<void>;
    getPhotosQty: (id: number) => Promise<any>;
    getPhotos: (id: number) => void;
    addPhoto: (id: number, files: any) => void;
    updatePhoto: (id: number) => void;
    deletePhoto: (id: number) => void;
    getShippments: () => void;
    getPrice: (
      ids: number[],
      insuranceFlag: boolean,
      packaging: string
    ) => Promise<DpdPriceInterface[] | undefined>;
    getExtras: (
      date: string,
      destinationId: number,
      tariff: string
    ) => Promise<DpdExtra[] | undefined>;
    getCargoPrice: (ids: number[]) => Promise<any>;
    createDeliveryOrder: (
      ids: number[],
      cargoPrice: number | null,
      insuranceFlag: boolean,
      passFlag: boolean,
      temperatureFlag: boolean,
      tariff: any,
      packaging: string,
      date: string
    ) => Promise<any>;
    getPurchaseOrder: (po: string) => void;
  };
  pac: PackageInterface | null;
  pacId: number | null;
  loading: boolean;
  photosQty: number | null;
  photos: Array<PhotoInterface>;
  shippments: ShippmentInterface[];
  selectedShippmentId: number | null;
  notification: string | null;
  headerString: string | null;
  deliveryOrderPickupDate: Dayjs;
  purchaseOrder: PurchaseOrderInterface | null;
}

const useAppStore = create<AppStore>((set) => ({
  apis: {
    getQR: (id) => getQRAction(id),
    updatePackage: (id, obj) => updatePackageAction(id, obj),
    getPhotosQty: (id: number) => getPhotosQtyAction(id),
    getPhotos: (id: number) => getPhotosAction(id),
    addPhoto: (id, files) => addPhotoAction(id, files),
    updatePhoto: (id) => updatePhotoAction(id),
    deletePhoto: (id) => deletePhotoAction(id),
    getShippments: () => getShippmentsAction(),
    getPrice: (ids, insuranceFlag, packaging) =>
      getPriceAction(ids, insuranceFlag, packaging),
    getExtras: (date, destinationId, tariff) =>
      getExtrasAction(date, destinationId, tariff),
    getCargoPrice: (ids) => getCargoPrice(ids),
    createDeliveryOrder: async (
      ids,
      cargoPrice,
      insuranceFlag,
      passFlag,
      temperatureFlag,
      tariff,
      packaging,
      date
    ) =>
      createDeliveryOrderAction(
        ids,
        cargoPrice,
        insuranceFlag,
        passFlag,
        temperatureFlag,
        tariff,
        packaging,
        date
      ),
    getPurchaseOrder: (po) => getPurchaseOrderByNameAction(po),
  },
  pac: null,
  pacId: null,
  loading: false,
  photosQty: 0,
  photos: [],
  shippments: [],
  selectedShippmentId: null,
  //   deliveryOrderId: null,
  notification: null,
  headerString: null,
  deliveryOrderPickupDate: dayjs(new Date()),
  purchaseOrder: null,
}));

export default useAppStore;
