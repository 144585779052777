import useAppStore from "../appStore";
import axiosInstance from "../../utils/Helpers";

// export const getPurchaseOrderByNameAction = async (po: string) => {
//   axiosInstance
//     .post("/order/byName", {po})
//     .then((res) => {
//       if (res.data) {
//         useAppStore.setState((state) => ({
//           purchaseOrder: res.data,
//         }));
//       }
//     })
//     .catch((err) => {
//       useAppStore.setState({
//         notification: "Ошибка при запросе на сервер",
//       });
//       //   Alert.alert('Ошибка при запросе на сервер, отгрузки неактуальны :)');
//     });
// };
export const getPurchaseOrderByNameAction = async (po: string) => {
  try {
    const res = await axiosInstance.post("/order/byName/", {
      po,
    });
    if (res.data) {
      useAppStore.setState((state) => ({
        purchaseOrder: res.data,
      }));
      //   return res.data;
    }
  } catch (err) {
    useAppStore.setState({
      notification: "Ошибка при запросе на сервер",
    });
  }
};
