import { Navigate, Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import DrawerNavigate from "../navigations/Drawer";

const ProtectedRoute = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  return (
    <Box
      style={{
        // marginTop: "3em",
        marginTop: 50,
        // backgroundColor: "#f3f3f3",
        height: getWindowDimensions().height - 50,
      }}
    >
      <DrawerNavigate />
      <Outlet />
    </Box>
  );
};
export default ProtectedRoute;
