import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import useAppStore from "../context/appStore";

export function Notification(): JSX.Element | null {
  const [notiOpen, setNotiOpen] = useState(false);
  const [notification] = useAppStore((state) => [state.notification]);

  useEffect(() => {
    if (notification) {
      setNotiOpen(true);
    }
  }, [notification]);

  const closeNotification = () => {
    useAppStore.setState({
      notification: null,
    });
    setNotiOpen(false);
  };

  return (
    <div>
      {notification ? (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={notiOpen}
          autoHideDuration={3000}
          onClose={() => closeNotification()}
          message={notification}
          action={
            <React.Fragment>
              <Button
                color="inherit"
                size="small"
                onClick={() => closeNotification()}
              >
                Закрыть
              </Button>
            </React.Fragment>
          }
        />
      ) : null}
    </div>
  );
}
