import axiosInstance from "../../utils/Helpers";
import useAppStore from "../appStore";

export const updatePackageAction = async (id: number, obj: any) => {
  try {
    await axiosInstance.put("/package/updatePackageByParam", {
      id,
      obj,
    });
  } catch (err) {
    useAppStore.setState({
      notification: "Ошибка при запросе на сервер",
    });
  }
};
