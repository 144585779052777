import { ChangeEvent, useEffect, useRef, useState } from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import CameraIcon from "@mui/icons-material/CameraAlt";
import UploadIcon from "@mui/icons-material/Upload";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ListAltIcon from "@mui/icons-material/ListAlt";

import produce from "immer";
import { useLocation, useNavigate } from "react-router-dom";
import useAppStore from "../context/appStore";
import { PackageInterface } from "../context/interfaces/package";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Skeleton,
} from "@mui/material";

import imageCompression from "browser-image-compression";

import { FreeMode, Thumbs, Zoom, type Swiper as SwiperRef } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";

const Package = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();

  // const path = useResolvedPath()
  const [
    pacId,
    pac,
    shippments,
    loading,
    photos,
    getPhotosQty,
    getPhotos,
    addPhoto,
    updatePhoto,
    deletePhoto,
    updatePackage,
  ] = useAppStore((state) => [
    state.pacId,
    state.pac,
    state.shippments,
    state.loading,
    state.photos,
    state.apis.getPhotosQty,
    state.apis.getPhotos,
    state.apis.addPhoto,
    state.apis.updatePhoto,
    state.apis.deletePhoto,
    state.apis.updatePackage,
  ]);

  const [qty, setQty] = useState<string>("");

  const [volume, setVolume] = useState<string>("");
  const [weight, setWeight] = useState<string>("");
  const [length, setLength] = useState<string>("");
  const [width, setWidth] = useState<string>("");
  const [height, setHeight] = useState<string>("");
  const [lastValue, seLastValue] = useState<string>("");
  const [photosQty, setPhotosQty] = useState<number | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputRefNoCapture = useRef<HTMLInputElement | null>(null);

  const [open, setOpen] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperRef | null>(null);
  const [initialSlide, setInitialSlide] = useState<number | null>(null);

  useEffect(() => {
    if (pac && pacId && pac.id === pacId) {
      useAppStore.setState({
        headerString: pac.purchaseOrder,
      });

      setQty(
        pac.qty + "" !== "null" && pac.qty + "" !== "0" ? pac.qty + "" : ""
      );
      setVolume(
        pac.volume + "" !== "null" && pac.volume + "" !== "0"
          ? pac.volume + ""
          : ""
      );
      setWeight(
        pac.weight + "" !== "null" && pac.weight + "" !== "0"
          ? pac.weight + ""
          : ""
      );
      setLength(
        pac.length + "" !== "null" && pac.length + "" !== "0"
          ? pac.length + ""
          : ""
      );
      setWidth(
        pac.width + "" !== "null" && pac.width + "" !== "0"
          ? pac.width + ""
          : ""
      );
      setHeight(
        pac.height + "" !== "null" && pac.height + "" !== "0"
          ? pac.height + ""
          : ""
      );
    } else {
      navigate("/scanner");
    }
  }, [navigate, pac, pacId]);

  useEffect(() => {
    if (pacId && photos.length === 0) {
      getPhotosQty(pacId).then((res) => {
        setPhotosQty(res.data.qty);
        getPhotos(pacId);
      });
    }
    // return () => {
    //   useAppStore.setState(
    //     produce((draft) => {
    //       draft.photos = [];
    //       draft.photosQty = null;
    //     })
    //   );
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pacId]);

  useEffect(() => {
    return () => {
      if (pac && pacId && state.fromRoute !== "scanner") {
        const savedPackage = useAppStore.getState().pac;
        useAppStore.setState(
          produce((draft) => {
            const shipIndex = shippments.findIndex(
              (s) => s.id === pac.shippment.id
            );
            const pacIndex = shippments[shipIndex].packages.findIndex(
              (p) => p.id === pac.id
            );
            draft.shippments[shipIndex].packages[pacIndex] = savedPackage;
          })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pacId]);

  const createDeliveryOrder = () => {
    if (pac) {
      let date = null;
      if (
        pac.shippment.id === 1 ||
        pac.shippment.id === 2 ||
        pac.shippment.id === 3 ||
        pac.shippment.id === 4 ||
        pac.shippment.id === 5 ||
        pac.shippment.id === 6
      ) {
        date = dayjs(new Date());
      } else {
        date = dayjs(pac.shippment.shippmentDate);
      }

      useAppStore.setState({
        deliveryOrderPickupDate: date,
      });

      navigate("/deliveryOrder", {
        state: {
          packages: [pac],
        },
      });
    }
  };

  const updateFromInputs = (param: string, val: string) => {
    const value = val.replace(/,/, ".");
    switch (param) {
      case "qty":
        setQty(value);
        break;
      case "volume":
        setVolume(value);
        break;
      case "weight":
        setWeight(value);
        break;
      case "length":
        setLength(value);
        break;
      case "width":
        setWidth(value);
        break;
      case "height":
        setHeight(value);

        break;
      default:
        break;
    }
    if (value[value.length - 1] !== "," && value[value.length - 1] !== ".") {
      if (param === "length" || param === "height" || param === "width") {
        updateVolume(param, value);
      }
      useAppStore.setState(
        produce((draft) => {
          draft.pac[param] = +value;
        })
      );
    }
  };

  const updateVolume = (param: string, value: string) => {
    if (value === "" || value === "0") {
      return;
    }
    switch (param) {
      case "length":
        if (width && height) {
          const vol = ((+value * +width * +height) / 1000000).toFixed(3);
          setVolume(vol);
          useAppStore.setState(
            produce((draft) => {
              draft.pac.volume = +vol;
              draft.pac.touchedVolume = true;
            })
          );
        }
        break;
      case "width":
        if (length && height) {
          const vol = ((+value * +length * +height) / 1000000).toFixed(3);
          setVolume(vol);
          useAppStore.setState(
            produce((draft) => {
              draft.pac.volume = +vol;
              draft.pac.touchedVolume = true;
            })
          );
        }
        break;
      case "height":
        if (length && width) {
          const vol = ((+value * +length * +width) / 1000000).toFixed(3);
          setVolume(vol);
          useAppStore.setState(
            produce((draft) => {
              draft.pac.volume = +vol;
              draft.pac.touchedVolume = true;
            })
          );
        }
        break;

      default:
        break;
    }
  };

  const updatePac = (param: string) => {
    if (pac) {
      if (pac[param as keyof PackageInterface] == lastValue) {
        return;
      }
      const obj = {
        [param]: pac[param as keyof PackageInterface],
      };
      if (param === "weight") {
        obj.touchedWeight = true;
      } else if (param === "volume") {
        obj.touchedVolume = true;
      } else if (
        volume &&
        (param === "length" || param === "height" || param === "width")
      ) {
        obj.volume = +volume;
        obj.touchedVolume = true;
      }
      updatePackage(pac.id, obj);
    }
  };

  const switchCrate = () => {
    if (pac) {
      const obj = {
        crate: pac.crate,
        pallete: pac.pallete,
      };
      if (!pac.crate && pac.pallete) {
        obj.pallete = false;
        useAppStore.setState(
          produce((draft) => {
            draft.pac.pallete = !pac.pallete;
          })
        );
      }

      useAppStore.setState(
        produce((draft) => {
          draft.pac.crate = !pac.crate;
        })
      );
      obj.crate = !pac.crate;
      updatePackage(pac.id, obj);
    }
  };

  const switchPallete = () => {
    if (pac) {
      const obj = {
        crate: pac.crate,
        pallete: pac.pallete,
      };
      if (!pac.pallete && pac.crate) {
        obj.crate = false;
        useAppStore.setState(
          produce((draft) => {
            draft.pac.crate = !pac.crate;
          })
        );
      }
      useAppStore.setState(
        produce((draft) => {
          draft.pac.pallete = !pac.pallete;
        })
      );
      obj.pallete = !pac.pallete;
      updatePackage(pac.id, obj);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const imageFile = e.target.files && e.target.files[0];
    if (!imageFile) {
      return;
    }
    if (pac) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1500,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        addPhoto(pac.id, [compressedFile]);
      } catch (error) {
        useAppStore.setState({
          notification: "Ошибка в добавлении фото",
        });
      }
    }
  };
  const handleCameraClick = () => {
    inputRef.current?.click();
  };

  const handleUploadClick = () => {
    inputRefNoCapture.current?.click();
  };

  const handleOpen = (i: number) => {
    setInitialSlide(i);
    setOpen(true);
  };
  const handleClose = () => {
    setInitialSlide(null);
    setThumbsSwiper(null);
    setOpen(false);
  };

  const openItemsList = () => {
    if (pac && pac.purchaseOrder) {
      navigate("/deliveryOrderItemsList", {
        state: {
          po: pac.purchaseOrder,
        },
      });
    }
  };

  return (
    <Container
      style={{
        paddingTop: 20,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <div
            style={{
              height: 50,
              // width: "100%",
              justifyContent: "space-between",
              display: "flex",
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            <Button
              style={{ color: theme.palette.text.primary }}
              onClick={() => handleClose()}
            >
              Закрыть
            </Button>
            <div
              style={{
                color: theme.palette.text.primary,
                alignSelf: "center",
                paddingRight: 8,
              }}
            >
              {initialSlide !== null ? initialSlide + 1 : 10} / {photos.length}
            </div>
          </div>
          <Divider />
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            modules={[FreeMode, Thumbs, Zoom]}
            thumbs={{ swiper: thumbsSwiper }}
            initialSlide={initialSlide ? initialSlide : 0}
            onSlideChange={(swiper) => setInitialSlide(swiper.activeIndex)}
            style={{
              width: "100%",
              flexGrow: 1,
            }}
            zoom={true}
          >
            {photos.map((item, keyIndex) => (
              <SwiperSlide key={keyIndex}>
                <div className="swiper-zoom-container">
                  <img src={item.image} alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Divider />
          <div
            style={{
              paddingBottom: 40,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
            }}
          >
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Thumbs]}
              initialSlide={initialSlide ? initialSlide : 0}
            >
              {photos.map((item, keyIndex) => (
                <SwiperSlide style={{ alignSelf: "center" }} key={keyIndex}>
                  <img
                    src={item.image}
                    alt=""
                    style={{ width: "5em", height: "5em" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Modal>
      <div style={{ marginBottom: "1em" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "1em",
          }}
        >
          <TextField
            inputProps={{ pattern: "[0-9]*", inputMode: "decimal" }}
            multiline={false}
            style={{ width: "30%" }}
            label="Кол-во"
            variant="outlined"
            value={qty}
            onChange={(event) => updateFromInputs("qty", event.target.value)}
            onBlur={() => updatePac("qty")}
            onFocus={() => seLastValue(qty ? qty : "")}
          />
          <TextField
            inputProps={{ pattern: "[0-9]*", inputMode: "decimal" }}
            style={{ width: "30%" }}
            label="Вес, кг"
            variant="outlined"
            value={weight}
            onChange={(event) => updateFromInputs("weight", event.target.value)}
            onBlur={() => updatePac("weight")}
            onFocus={() => seLastValue(weight ? weight : "")}
          />
          <TextField
            inputProps={{ pattern: "[0-9]*", inputMode: "decimal" }}
            style={{ width: "30%" }}
            label="Объем"
            variant="outlined"
            value={volume}
            onChange={(event) => updateFromInputs("volume", event.target.value)}
            onBlur={() => updatePac("volume")}
            onFocus={() => seLastValue(volume ? volume : "")}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBlockEnd: "1em",
          }}
        >
          <TextField
            inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
            style={{ width: "30%" }}
            label="Д, см"
            variant="outlined"
            value={length}
            onChange={(event) => updateFromInputs("length", event.target.value)}
            onFocus={() => seLastValue(length ? length : "")}
            onBlur={() => updatePac("length")}
          />
          <TextField
            inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
            style={{ width: "30%" }}
            label="Ш, см"
            variant="outlined"
            value={width}
            onChange={(event) => updateFromInputs("width", event.target.value)}
            onFocus={() => seLastValue(width ? width : "")}
            onBlur={() => updatePac("width")}
          />
          <TextField
            inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
            style={{ width: "30%" }}
            label="В, см"
            variant="outlined"
            value={height}
            onChange={(event) => updateFromInputs("height", event.target.value)}
            onFocus={() => seLastValue(height ? height : "")}
            onBlur={() => updatePac("height")}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Box
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Switch
              style={{ marginRight: 10 }}
              checked={pac?.crate}
              onChange={switchCrate}
            />
            <div>Обрешетка</div>
          </Box>
          <Box
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Switch
              style={{ marginRight: 10 }}
              checked={pac?.pallete}
              onChange={() => switchPallete()}
            />
            <div>П/Б</div>
          </Box>
        </Box>
      </div>
      <div style={{ overflow: "auto", flex: 1 }}>
        {loading && photosQty ? (
          <ImageList
            sx={{
              width: "auto",
              height: "auto",
              marginBlockStart: 0,
              marginBlockEnd: 0,
            }}
            cols={2}
            rowHeight={150}
          >
            {[...Array(photosQty)].map((val, i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                width={"auto"}
                height={150}
              />
            ))}
          </ImageList>
        ) : (
          <ImageList
            sx={{
              width: "auto",
              height: "auto",
              marginBlockStart: 0,
              marginBlockEnd: 0,
            }}
            cols={2}
            rowHeight={150}
          >
            {photos.map((item, index) => (
              <ImageListItem key={item.id}>
                <img
                  src={item.image}
                  style={
                    item.isSelected
                      ? { height: 100 }
                      : { opacity: 0.5, height: 100 }
                  }
                  alt={item.id + ""}
                  loading="lazy"
                  onClick={() => handleOpen(index)}
                />
                <ImageListItemBar
                  actionIcon={
                    <Grid container gap={2}>
                      <Grid item>
                        <IconButton
                          onClick={() => updatePhoto(item.id)}
                          sx={{ color: "white" }}
                        >
                          {item.isSelected ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => deletePhoto(item.id)}
                          sx={{ color: "white" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </div>
      <Divider />

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: 30,
        }}
      >
        <IconButton
          aria-label="photo"
          // size="large"
          onClick={handleCameraClick}
        >
          <CameraIcon sx={{ fontSize: 25 }} />
        </IconButton>
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          capture
          accept="image/*"
        />
        <IconButton
          aria-label="upload"
          // sx={{ fontSize: 40 }}
          onClick={handleUploadClick}
        >
          <UploadIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <input
          style={{ display: "none" }}
          ref={inputRefNoCapture}
          type="file"
          onChange={handleFileChange}
          accept="image/*"
        />
        <IconButton
          aria-label="upload"
          // sx={{ fontSize: 40 }}
          onClick={() => openItemsList()}
        >
          <ListAltIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="large"
          onClick={() => createDeliveryOrder()}
          disabled={
            pac?.deliveryOrderId || !pac?.qty || !pac?.weight || !pac.volume
              ? true
              : false
          }
        >
          <LocalShippingIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Box>
    </Container>
  );
};

export default Package;
