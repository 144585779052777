import useAppStore from "../appStore";
import axiosInstance from "../../utils/Helpers";

export const getQRAction = async (id: number) => {
  try {
    const res = await axiosInstance.get("/package/getOne/" + id);
    if (res.data) {
      useAppStore.setState({
        pacId: res.data.id,
        pac: res.data,
      });
    } else {
      useAppStore.setState({
        notification: "Нет такой отправки",
      });
      // useAppStore.setState((state) => ({
      //   pacId: undefined,
      // }));
    }
  } catch (err) {
    useAppStore.setState({
      notification: "Ошибка при запросе на сервер",
    });
  }
};
