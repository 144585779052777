import { useEffect, useState } from "react";
import useAppStore from "../context/appStore";
import { useZxing } from "react-zxing";
import { useNavigate } from "react-router";

const Scanner = () => {
  let navigate = useNavigate();
  const [pac, pacId, getQR] = useAppStore((state) => [
    state.pac,
    state.pacId,
    state.apis.getQR,
  ]);

  const [paused, setPaused] = useState<boolean>(true);

  useEffect(() => {
    useAppStore.setState({
      pacId: null,
      pac: null,
      photos: [],
    });
  }, []);

  useEffect(() => {
    if (pacId) {
      setPaused(true);
      navigate("/package", {
        state: {
          fromRoute: "scanner",
          header: pac?.purchaseOrder,
        },
      });
    } else {
      setPaused(false);
    }
  }, [navigate, pac?.purchaseOrder, pacId, paused]);

  const qrHandler = (e: string) => {
    const y = JSON.parse(e);
    getQR(+y.id);
  };

  let { ref } = useZxing({
    paused: paused,
    onResult(result) {
      qrHandler(result.getText());
    },
    constraints: { video: { facingMode: "environment" }, audio: false },
    timeBetweenDecodingAttempts: 600,
  });

  return (
    <div>
      <video style={{ width: "100%" }} ref={ref}></video>
    </div>
  );
};

export default Scanner;
