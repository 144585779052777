import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Grid } from "@mui/material";
import LoginScreen from "../screens/Login";
import ScannerScreen from "../screens/Scanner";
import ProtectedRoute from "../utils/ProtectedRoute";
import useAuthStore from "../context/authStore";
import PackageList from "../screens/PackagesList";
import Package from "../screens/Package";
import Spinner from "./MySpinner";
import Shippments from "../screens/Shippments";
import { Notification } from "../utils/Notification";
import DeliveryOrder from "../screens/DeliveryOrder";
import DeliveryOrderItemsList from "../screens/DeliveryOrderItemsList";

const AppRouter = () => {
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);

  if (!isLoggedIn) {
    const token = localStorage.getItem("token");
    if (token) {
      useAuthStore.setState({
        isLoggedIn: true,
      });
    }
  }

  return (
    <Grid className="container">
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="*" element={<LoginScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
            <Route path="/scanner" element={<ScannerScreen />} />
            <Route path="/shippments" element={<Shippments />} />
            <Route path="/packagelist" element={<PackageList />} />
            <Route path="/package" element={<Package />} />
            <Route path="/deliveryOrder" element={<DeliveryOrder />} />
            <Route
              path="/deliveryOrderItemsList"
              element={<DeliveryOrderItemsList />}
            />
          </Route>
        </Routes>
      </Suspense>
      <Notification />
    </Grid>
  );
};

export default AppRouter;
