
import axios, { InternalAxiosRequestConfig } from 'axios'

let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
}

const axiosInstance = axios.create({
    // baseURL: 'http://localhost:3600',
    baseURL: 'https://api.sigmacompany.ru',
    headers
})

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem("token")
        if (token) {
            if (config.headers) {
                config.headers.Authorization = 'Bearer ' + token
            }
        }
        return config
    }, (error) => {
        return Promise.reject(error);
    })

export default axiosInstance

