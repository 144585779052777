import { create } from "zustand";
import axiosInstance from "../utils/Helpers";
import useAppStore from "./appStore";

interface AuthInterface {
  loading: boolean;
  isLoggedIn: boolean;
  isDarkMode: boolean | null;
  login: (login: string, password: string) => void;
  logout: () => void;
}

const useAuthStore = create<AuthInterface>((set) => ({
  loading: false,
  isLoggedIn: false,
  isDarkMode: null,
  login: (login, password) => {
    axiosInstance
      .post("/login", {
        login,
        password,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        set((state) => ({
          loading: false,
          isLoggedIn: true,
        }));
      })
      .catch((err) => {
        set((state) => ({
          isLoggedIn: false,
        }));
        useAppStore.setState({
          notification: "Ошибка при запросе на сервер",
        });
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    set((state) => ({
      loading: false,
      isLoggedIn: false,
    }));
  },
}));

export default useAuthStore;
