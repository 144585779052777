import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./navigations/AppRouter";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { PaletteMode } from "@mui/material";
import useAuthStore from "./context/authStore";

import { ruRU } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const App: React.FC = () => {
  const [isDarkMode] = useAuthStore((state) => [state.isDarkMode]);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    if (isDarkMode === null) {
      const isDarkInCookies = localStorage.getItem("isDarkMode");
      if (isDarkInCookies === null) {
        useAuthStore.setState({
          isDarkMode: prefersDarkMode ? true : false,
        });
        localStorage.setItem("isDarkMode", prefersDarkMode ? "true" : "false");
      } else {
        useAuthStore.setState({
          isDarkMode: isDarkInCookies === "true" ? true : false,
        });
      }
    } else {
      localStorage.setItem("isDarkMode", isDarkMode ? "true" : "false");
    }
  }, [isDarkMode, prefersDarkMode]);

  const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            // primary: amber,
            // divider: amber[200],
            // text: {
            //   primary: grey[900],
            //   secondary: grey[800],
            // },
            background: {
              default: "#f3f3f3",
            },
            menu: {
              main: "#BAD7FF",
            },
          }
        : {
            // palette values for dark mode
            // primary: deepOrange,
            // divider: deepOrange[700],
            background: {
              // default: deepOrange[900],
              paper: "#1F1B24",
            },
            // text: {
            //   primary: "#fff",
            //   secondary: grey[500],
            // },
            menu: {
              main: "#1F1B24",
            },
          }),
    },
  });

  const theme = React.useMemo(
    () => createTheme(getDesignTokens(isDarkMode ? "dark" : "light"), ruRU),
    [isDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        // localeText={
        //   ruRU.components.MuiLocalizationProvider.defaultProps.localeText
        // }
        adapterLocale="ru"
        dateAdapter={AdapterDayjs}
      >
        <CssBaseline />
        <Router>
          <AppRouter />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
