import produce from "immer";
import useAppStore from "../appStore";
import axiosInstance from "../../utils/Helpers";
import FormData from "form-data";
import { PhotoInterface } from "../interfaces/photo";

export const getPhotosQtyAction = (id: number): Promise<any> => {
  useAppStore.setState((state) => ({
    photosQty: null,
  }));
  return axiosInstance.get("/getPhotosQty/" + id);

  // axiosInstance
  //   .get("/getPhotosQty/" + id)
  //   .then((res) => {
  //     useAppStore.setState((state) => ({
  //       photosQty: res.data,
  //     }));
  //     result = 0
  //   })
  //   .catch((err) => {
  //     useAppStore.setState({
  //       notification: "Ошибка при запросе на сервер",
  //     });
  //     return 0
  //   });
  //   return result
};

export const getPhotosAction = (id: number) => {
  useAppStore.setState((state) => ({
    photos: [],
    loading: true,
  }));
  axiosInstance
    .get("/getMiniPhotosForApp/" + id)
    .then((res) => {
      useAppStore.setState((state) => ({
        photos: res.data,
        loading: false,
      }));
    })
    .catch((err) => {
      useAppStore.setState((state) => ({
        loading: false,
      }));
      useAppStore.setState({
        notification: "Ошибка при запросе на сервер",
      });
    });
};

export const addPhotoAction = (id: number, files: any) => {
  const formData = new FormData();
  for (const photo of files) {
    // if (!photo.uri) {
    //   return;
    // }
    // formData.append("uploadingFiles", {
    //   name: photo.fileName,
    //   type: photo.type,
    //   data: photo,
    // });
    formData.append("uploadingFiles", photo);
  }
  axiosInstance
    .post("/uploadPackagePhotoFromApp/" + id, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      for (const item of res.data) {
        const imageUri = files.find((a: any) => a.size === item.name);

        const blobToBase64 = (blob: Blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Promise((resolve) => {
            reader.onloadend = () => {
              resolve(reader.result);
            };
          });
        };
        blobToBase64(imageUri).then((res) => {
          useAppStore.setState(
            produce((draft) => {
              draft.photos.push({
                image: res,
                id: item.id,
                isSelected: item.isSelected,
              });
            })
          );
        });
      }
    })
    .catch((err) => {
      useAppStore.setState({
        notification: "Ошибка при запросе на сервер",
      });
    });
};
export const updatePhotoAction = (id: number) => {
  let isSelected;
  useAppStore.setState(
    produce((draft) => {
      const index = draft.photos.findIndex((p: PhotoInterface) => p.id === id);
      if (index !== -1) {
        isSelected = !draft.photos[index].isSelected;
        draft.photos[index].isSelected = !draft.photos[index].isSelected;
      }
    })
  );
  axiosInstance
    .post("/updatePhoto/" + id, { selected: isSelected })
    .catch((err) => {
      useAppStore.setState({
        notification: "Ошибка при запросе на сервер",
      });
    });
};
export const deletePhotoAction = (id: number) => {
  axiosInstance
    .delete("/photo/" + id)
    .then(() => {
      useAppStore.setState(
        produce((draft) => {
          const index = draft.photos.findIndex(
            (p: PhotoInterface) => p.id === id
          );
          if (index !== -1) {
            draft.photos.splice(index, 1);
          }
        })
      );
    })
    .catch((err) => {
      useAppStore.setState({
        notification: "Ошибка при запросе на сервер",
      });
    });
};
