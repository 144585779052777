import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import ListIcon from "@mui/icons-material/ListAlt";
import MenuIcon from "@mui/icons-material/Menu";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../context/authStore";
import QrIcon from "@mui/icons-material/QrCode2";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { type Dayjs } from "dayjs";

import ButtonDatePicker from "./ButtonDatePicker";
import useAppStore from "../context/appStore";
type Anchor = "left" | "right";

const DrawerNavigate = () => {
  let navigate = useNavigate();
  const theme = useTheme();

  const [logout] = useAuthStore((state) => [state.logout]);
  const [isDarkMode] = useAuthStore((state) => [state.isDarkMode]);
  const [headerString, deliveryOrderPickupDate] = useAppStore((state) => [
    state.headerString,
    state.deliveryOrderPickupDate,
  ]);

  const { pathname } = useLocation();
  const [anchor, setAnchor] = useState<Anchor>("left");

  const [drawerState, setDrawerState] = useState({
    left: false,
    right: false,
  });

  const [showBackArrow, setShowBackArrow] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    if (pathname === "/scanner") {
      useAppStore.setState({
        headerString: "QR сканер",
      });
      setAnchor("left");
      setShowBackArrow(false);
      setShowDatePicker(false);
    } else if (pathname === "/shippments") {
      setAnchor("left");
      setShowBackArrow(false);
      setShowDatePicker(false);
    } else if (pathname === "/deliveryOrder") {
      setShowBackArrow(true);
      setShowDatePicker(true);
    } else {
      setAnchor("right");
      setShowBackArrow(true);
      setShowDatePicker(false);
    }
  }, [pathname]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerState({ ...drawerState, [anchor]: open });
    };

  const returnBack = () => {
    navigate(-1);
  };

  const changeColorMode = () => {
    useAuthStore.setState({
      isDarkMode: isDarkMode ? false : true,
    });
  };

  const setDeliveryOrderPickupDate = (date: Dayjs) => {
    useAppStore.setState({
      deliveryOrderPickupDate: date,
    });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton onClick={() => navigate("/scanner")}>
            <ListItemIcon>
              <QrIcon />
            </ListItemIcon>
            <ListItemText primary={"Сканер"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => navigate("/shippments")}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary={"Отгрузки"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          {theme.palette.mode === "dark" ? (
            <ListItemButton onClick={() => changeColorMode()}>
              <ListItemIcon>
                <Brightness7Icon />
              </ListItemIcon>
              <ListItemText primary={"Светлая тема"} />
            </ListItemButton>
          ) : (
            <ListItemButton onClick={() => changeColorMode()}>
              <ListItemIcon>
                <Brightness4Icon />
              </ListItemIcon>
              <ListItemText primary={"Темная тема"} />
            </ListItemButton>
          )}
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              logout();
              navigate("/login");
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Выход"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box
      style={{
        backgroundColor: theme.palette.menu.main,
        display: "flex",
        position: "fixed",
        top: 0,
        zIndex: 100,
        width: "100%",
        flexDirection: "row",
        height: 50,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      {showBackArrow ? (
        <IconButton aria-label="back" onClick={() => returnBack()}>
          <ArrowBackIosIcon />
        </IconButton>
      ) : (
        <IconButton aria-label="menu" onClick={toggleDrawer(anchor, true)}>
          <MenuIcon />
        </IconButton>
      )}

      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          open={drawerState[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
      <Box
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingRight: showBackArrow ? 0 : 40,
        }}
      >
        {headerString}
      </Box>

      {showBackArrow ? (
        showDatePicker ? (
          <ButtonDatePicker
            value={deliveryOrderPickupDate}
            closeOnSelect={true}
            onAccept={(value) =>
              value ? setDeliveryOrderPickupDate(value) : null
            }
          />
        ) : (
          <IconButton aria-label="menu" onClick={toggleDrawer(anchor, true)}>
            <MenuIcon />
          </IconButton>
        )
      ) : null}
    </Box>
  );
};

export default DrawerNavigate;
