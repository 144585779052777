import useAppStore from "../appStore";
import axiosInstance from "../../utils/Helpers";

export const getShippmentsAction = () => {
  axiosInstance
    .get("/mobile/shippments")
    .then((res) => {
      if (res.data) {
        useAppStore.setState((state) => ({
          shippments: res.data,
        }));
      }
    })
    .catch((err) => {
      useAppStore.setState({
        notification: "Ошибка при запросе на сервер",
      });
      //   Alert.alert('Ошибка при запросе на сервер, отгрузки неактуальны :)');
    });
};
