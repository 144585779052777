import { Box, List, ListItem } from "@mui/material";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useAppStore from "../context/appStore";
import { useLocation } from "react-router";
import { ItemInterface } from "../context/interfaces/item-interface";

const DeliveryOrderItemsList = () => {
  const theme = useTheme();
  const { state } = useLocation();

  const [purchaseOrder, getPurchaseOrder] = useAppStore((state) => [
    state.purchaseOrder,
    state.apis.getPurchaseOrder,
  ]);
  const [pac] = useAppStore((state) => [state.pac]);
  useEffect(() => {
    getPurchaseOrder(state.po);
  }, [getPurchaseOrder]);

  const getQtyToShip = (itemFromList: ItemInterface) => {
    if (!pac) {
      return 0;
    }
    let packsQty = 0;

    const pack = itemFromList.itemPackages.find(
      (itemPackage) => itemPackage.packageId === pac.id
    );
    if (pack) {
      packsQty = pack.qty;
    }

    return packsQty;
  };

  const getShippedBefore = (item: ItemInterface) => {
    if (!pac) {
      return 0;
    }
    const newPackages = [];
    for (const i of item.itemPackages) {
      if (i.packageId !== pac.id) {
        newPackages.push(i);
      }
    }
    let shippedQty = 0;
    if (newPackages.length > 0) {
      shippedQty = newPackages
        .map((itemPackage) => itemPackage.qty)
        .reduce((acc, curVal) => acc + curVal);
    }
    return shippedQty;
  };

  const getBackgroudColor = (item: ItemInterface) => {
    if (item.itemPackages.length > 0) {
      const shipped = item.itemPackages
        .map((itemPackage) => itemPackage.qty)
        .reduce((acc, curVal) => acc + curVal);
      if (shipped === item.quantity) {
        return true;
      }
      return false;
    }
    return false;
  };

  const getBackgroundColorPartShipped = (item: ItemInterface) => {
    if (item.itemPackages.length > 0) {
      const shipped = item.itemPackages
        .map((itemPackage) => itemPackage.qty)
        .reduce((acc, curVal) => acc + curVal);
      if (shipped === item.quantity) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <Box>
      <List style={{ padding: 0 }}>
        {purchaseOrder?.items.map((item, i) => (
          <ListItem key={i} style={{ padding: 0 }} divider={true}>
            <Box
              style={{
                display: "flex",
                // alignItems: "left",
                backgroundColor: getBackgroudColor(item)
                  ? "green"
                  : getBackgroundColorPartShipped(item)
                  ? "orange"
                  : theme.palette.background.paper,
                // justifyContent: "space-between",
                flexDirection: "column",
                // backgroundColor: theme.palette.background.paper,
                width: "100%",
                paddingRight: 10,
                paddingLeft: 10,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <Box
                style={{
                  // fontWeight: "300",
                  fontSize: 12,
                }}
              >
                {item.lineName}
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Box
                  style={{
                    fontSize: 12,
                    fontWeight: 300,
                    alignSelf: "center",
                    // flex: 2,
                    whiteSpace: "nowrap",
                  }}
                >
                  Кол-во: {item.quantity}
                </Box>
                {getShippedBefore(item) !== 0 ? (
                  <Box style={{ fontWeight: "300", fontSize: 12 }}>
                    Отгружено ранее: {getShippedBefore(item)}
                  </Box>
                ) : null}
                <Box style={{ fontWeight: "300", fontSize: 12 }}>
                  Отгружаем: {getQtyToShip(item)}
                </Box>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DeliveryOrderItemsList;
